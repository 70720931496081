import React, { useEffect, useState, useRef } from 'react';
import { PanelProps, DataHoverEvent, LegacyGraphHoverEvent } from '@grafana/data';
import { AssetMode, SimpleOptions, SimulationType, PanelType } from 'types';
import { toHourString, getColor, generateDescription, getItemByKey } from 'utilities';

import { Viewer, Clock, Entity, ModelGraphics, PathGraphics } from 'resium';
import {
  Ion,
  JulianDate,
  TimeInterval,
  TimeIntervalCollection,
  Cartesian3,
  Quaternion,
  Transforms,
  SampledProperty,
  SampledPositionProperty,
  Color,
  PolylineDashMaterialProperty,
  IonResource,
  Cartesian2,
  ClockRange,
  VelocityOrientationProperty,
  Math as MathCesium,
  HeadingPitchRoll,
  Ellipsoid,
  Matrix4,
  DebugModelMatrixPrimitive,
  LabelStyle,
  VerticalOrigin,
} from 'cesium';

import 'cesium/Build/Cesium/Widgets/widgets.css';
import '../css/output.css';
import { TopControl } from './TopControl';
import { Legend, SeriesLegend } from './SeriesLegend';
import 'bootstrap-icons/font/bootstrap-icons.css';

interface Props extends PanelProps<SimpleOptions> {}

const DEFAULT_LAT = 35.652832;
const DEFAULT_LONG = 139.839478;
const DEFAULT_HEIGHT = 10000;

const setCameraFly = (viewer: any, data: any, isStaticPanel: boolean, finishCallback: any = () => {}, renderAxis: any)  => {
  const dataFrame = data.series[0];
  if (!dataFrame?.fields) {
    return;
  }
  const longData = getItemByKey('name', 'longitude', dataFrame.fields)?.values || [];
  const latData = getItemByKey('name', 'latitude', dataFrame.fields)?.values || [];
  const altData = getItemByKey('name', 'altitude', dataFrame.fields)?.values || [];
  const firstLat = Number(latData[0]) || DEFAULT_LAT;
  const firstLong = Number(longData[0]) || DEFAULT_LONG;
  const firstHeight = Number(altData[0]) || DEFAULT_HEIGHT;
  const destination = Cartesian3.fromDegrees(firstLong, firstLat, DEFAULT_HEIGHT);
  const heading = MathCesium.toRadians(90.0);
  const pitch = MathCesium.toRadians(-45.0);  
  const roll = 0.0;

  viewer?.camera?.flyTo({
    destination: destination,
    orientation: {
      heading: heading,
      pitch: pitch,
      roll: roll,
    },
    duration: 2,
    complete: async () => {
      renderAxis(firstLong, firstLat, firstHeight);
      await new Promise(resolve => setTimeout(resolve, 1000));
      if (!viewer.scene) {
        finishCallback();
        return;
      }
      viewer.trackedEntity = viewer.entities.values[0];
      await new Promise(resolve => setTimeout(resolve, 1000));
      if (isStaticPanel) {
        viewer.camera.rotateLeft(MathCesium.toRadians(135.0));
        viewer.camera.zoomOut(100);
      } else {
        viewer.camera.zoomOut(20000);
        viewer.trackedEntity = null;
      }
      await new Promise(resolve => setTimeout(resolve, 2000));
      finishCallback();
    }
  });
};

const setTimelineViewer = (
  viewer: any,
  data: any,
  setSatelliteAvailability: any,
  setTimestamp: any,
  setShouldAnimate: any
) => {
  if (!data?.series?.length) {
    return;
  }
  let allTimes = data.series
    .flatMap((item: any) => {
      const timeData = getItemByKey('name', 'time', item.fields)?.values || [];
      return [timeData[0], timeData.at(-1)]
    }).map((time: string) => new Date(time))
    .sort((a: any, b: any) => a - b);
  if (!viewer) {
    return;
  }
  const { timeline, clock } = viewer;
  const startTimestamp: number | null = allTimes[0] ?? null;
  const endTimestamp: number | null = allTimes.at(-1) ?? null;

  if (timeline) {
    timeline.makeLabel = function (date: any) {
      const timeBySecond = JulianDate.secondsDifference(date, JulianDate.fromDate(new Date(startTimestamp || '')));
      return toHourString(timeBySecond);
    };
  }

  if (startTimestamp !== null) {
    setTimestamp(JulianDate.fromDate(new Date(startTimestamp)));
  } else {
    setTimestamp(null);
  }

  if (startTimestamp && endTimestamp) {
    setSatelliteAvailability(
      new TimeIntervalCollection([
        new TimeInterval({
          start: JulianDate.fromDate(new Date(startTimestamp)),
          stop: JulianDate.fromDate(new Date(endTimestamp)),
        }),
      ])
    );
  } else {
    setSatelliteAvailability(null);
  }

  if (viewer && clock && timeline && startTimestamp && endTimestamp) {
    const start = JulianDate.fromDate(new Date(startTimestamp));
    const stop = JulianDate.fromDate(new Date(endTimestamp));
    clock.startTime = start.clone();
    clock.stopTime = stop.clone();
    clock.currentTime = start.clone();
    clock.clockRange = ClockRange.LOOP_STOP;
    timeline.zoomTo(start, stop);
    setShouldAnimate(false);
  }
};

export const SatelliteVisualizer: React.FC<Props> = ({ options, data, timeRange, width, height, eventBus }) => {
  Ion.defaultAccessToken = options.accessToken;
  const viewerRef = useRef<any>(null);
  const modelRef = useRef<any>(null);
  const wrapRef = useRef<any>(null);

  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [isFinishLoad, setIsFinishLoad] = useState<boolean>(false);
  const [isBack, setIsBack] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);
  const [timestamp, setTimestamp] = useState<JulianDate | null>(null);
  const [satelliteAvailability, setSatelliteAvailability] = useState<TimeIntervalCollection | null>(null);
  const [satellitePositions, setSatellitePositions] = useState<SampledPositionProperty[] | null>(null);
  const [satelliteOrientations, setSatelliteOrientations] = useState<SampledProperty[] | null>(null);
  const [satelliteResources, setSatelliteResources] = useState<IonResource[] | string[] | any[]>([]);
  const [clockMultiplier, setClockMultiplier] = useState<number>(1);
  const [hoveredIndex, setHoveredIndex] = useState<{ serie: number; position: number } | null>(null);
  const [legends, setLegends] = useState<Legend[]>([]);
  const isStaticPanel = () => options?.panelType === PanelType.static;

  const handleMouseMove = (serie: number, position: number) => {
    setHoveredIndex({ serie, position });
  };
  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleSpeedChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseFloat(event.target.value);
    dispatchAction('speedChange', {speed: value});
  };

  const playReverse = () => {
    const currentSpeed = clockMultiplier === 0 ? -1 : clockMultiplier;
    const speed = currentSpeed > 0 ? currentSpeed * -1 : currentSpeed;
    dispatchAction('playReverse', {speed, back: true, play: true});
  };

  const playForward = () => {
    const currentSpeed = clockMultiplier === 0 ? 1 : clockMultiplier;
    const speed = currentSpeed < 0 ? currentSpeed * -1 : currentSpeed;
    dispatchAction('playForward', {speed, back: false, play: true});
  };

  const pause = () => {
    dispatchAction('pause', {});
  };

  const initLegends = (series: any) => {
    let newLegends: any = [];
    for (const [index, serie] of series.entries()) {
      newLegends = [...newLegends, { id: index, name: serie.refId || '', color: getColor(index), is_selected: true }];
    }
    setLegends(newLegends);
  };

  const setResources = (dataFrame: any, index: number) => {
    const modelAssetId = dataFrame.fields[12]?.values[0];
    if (modelAssetId) {
      IonResource.fromAssetId(modelAssetId, { accessToken: options.accessToken })
        .then((resource) => {
          setSatelliteResources(oldVal => ({
            ...oldVal,
            [index]: resource,
          }));
        })
        .catch((error) => {
          console.error('Error loading Ion Resource of Model:', error);
          setSatelliteResources(oldVal => ({
            ...oldVal,
            [index]: null,
          }));
        });
      return;
    }

    if (options.modelAssetId) {
      IonResource.fromAssetId(options.modelAssetId, { accessToken: options.accessToken })
        .then((resource) => {
          setSatelliteResources(oldVal => ({
            ...oldVal,
            [index]: resource,
          }));
        })
        .catch((error) => {
          console.error('Error loading Ion Resource of Model:', error);
          setSatelliteResources(oldVal => ({
            ...oldVal,
            [index]: null,
          }));
        });
      return;
    }
    if (options.modelAssetUri) {
      setSatelliteResources(oldVal => ({
        ...oldVal,
        [index]: options.modelAssetUri,
      }));
      return;
    }

    setSatelliteResources(oldVal => ({
      ...oldVal,
      [index]: null,
    }));
  };

  useEffect(() => {
    const currentDate = new Date();
    const timeInterval = new TimeInterval({
      start: JulianDate.addDays(JulianDate.fromDate(currentDate), -7, new JulianDate()),
      stop: JulianDate.fromDate(currentDate),
    });
    // https://community.cesium.com/t/correct-way-to-wait-for-transform-to-be-ready/24800
    Transforms.preloadIcrfFixed(timeInterval).then(() => setLoaded(true));
  }, [timeRange]);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    if (data.series.length) {
      const positionProperties = [];
      const orientationProperties = [];
      for (const serie of data.series) {
        const positionProperty = new SampledPositionProperty();
        const orientationProperty = new SampledProperty(Quaternion);
        const timeData = getItemByKey('name', 'time', serie.fields)?.values || [];
        const longData = getItemByKey('name', 'longitude', serie.fields)?.values || [];
        const latData = getItemByKey('name', 'latitude', serie.fields)?.values || [];
        const altData = getItemByKey('name', 'altitude', serie.fields)?.values || [];
        const q_B_ECI_xData = getItemByKey('name', 'q_B_ECI_x', serie.fields)?.values || [];
        const q_B_ECI_yData = getItemByKey('name', 'q_B_ECI_y', serie.fields)?.values || [];
        const q_B_ECI_zData = getItemByKey('name', 'q_B_ECI_z', serie.fields)?.values || [];
        const q_B_ECI_sData = getItemByKey('name', 'q_B_ECI_s', serie.fields)?.values || [];
        const headingData = getItemByKey('name', 'yaw', serie.fields)?.values || [];
        const pitchData = getItemByKey('name', 'pitch', serie.fields)?.values || [];
        const rollData = getItemByKey('name', 'roll', serie.fields)?.values || [];

        for (const [index, value] of timeData.entries()) {
          const time = JulianDate.fromDate(new Date(value));
          const dataIndex = (options.panelType === PanelType.static) ? 0 : index;
          
          const x_ECEF = Cartesian3.fromDegrees(
            Number(longData[dataIndex]),
            Number(latData[dataIndex]),
            Number(altData[dataIndex])
          );
          let q_B_ECI = new Quaternion();
          if (headingData?.length && pitchData?.length && rollData?.length) {
            const heading = Number(headingData?.[index]);
            const pitch = Number(pitchData?.[index]);
            const roll = Number(rollData?.[index]);
            const fixedFrameTransform = Transforms.localFrameToFixedFrameGenerator('north', 'west');
            const ellipsoid = Ellipsoid.WGS84;
            q_B_ECI = Transforms.headingPitchRollQuaternion(x_ECEF, new HeadingPitchRoll(heading, pitch, roll), ellipsoid, fixedFrameTransform);
          }

          if (options.simulationType?.toLowerCase() === SimulationType.SixDoF?.toLowerCase()) {
            q_B_ECI = new Quaternion(
              Number(q_B_ECI_xData[index]),
              Number(q_B_ECI_yData[index]),
              Number(q_B_ECI_zData[index]),
              Number(q_B_ECI_sData[index])
            );
          }
          positionProperty.addSample(time, x_ECEF);
          orientationProperty.addSample(time, q_B_ECI);
        }
       
        positionProperties.push(positionProperty);
        orientationProperties.push(orientationProperty);
      }

      setSatellitePositions(positionProperties);
      setSatelliteOrientations(orientationProperties);
    }
  }, [data, isLoaded, options]);

  const checkViewerAvailable: any = async () => {
    const viewer = viewerRef?.current?.cesiumElement;
    if (viewer && data?.series?.length) {
      if (isStaticPanel()) {
        viewer.scene.globe.show = false;
        viewer.scene.skyBox.show = false;
        viewer.scene.skyAtmosphere.show = false;
        viewer.scene.sun.show = false;
      }
      setCameraFly(viewer, data, isStaticPanel(), () => setIsFinishLoad(true), drawAxis);
      setTimelineViewer(viewer, data, setSatelliteAvailability, setTimestamp, setShouldAnimate);
      initLegends(data.series);
    } else {
      await new Promise(resolve => setTimeout(resolve, 500));
      await checkViewerAvailable();
    }
  };

  const drawAxis = (long: any, lat: any, height: any) => {
    if (!isStaticPanel()) {
      return;
    }
    const viewer = viewerRef?.current?.cesiumElement;
    const fixedFrameTransform = Transforms.localFrameToFixedFrameGenerator('north', 'west');
    const length = 50;
    const position = Cartesian3.fromDegrees(long, lat, height);
    const modelMatrix = Transforms.headingPitchRollToFixedFrame(
      position,
      new HeadingPitchRoll(),
      Ellipsoid.WGS84,
      fixedFrameTransform,
    );
    viewer.scene.primitives.add(
      new DebugModelMatrixPrimitive({
        modelMatrix: modelMatrix,
        length: length,
        width: 1.0,
      }),
    );
    const xEnd = new Cartesian3(length, 0.0, 0.0);
    const yEnd = new Cartesian3(0.0, length, 0.0);
    const zEnd = new Cartesian3(0.0, 0.0, length);

    const xEndWorld = Matrix4.multiplyByPoint(modelMatrix, xEnd, new Cartesian3());
    const yEndWorld = Matrix4.multiplyByPoint(modelMatrix, yEnd, new Cartesian3());
    const zEndWorld = Matrix4.multiplyByPoint(modelMatrix, zEnd, new Cartesian3());
    addAxisToViewer(viewer, xEndWorld, 'X', Color.RED);
    addAxisToViewer(viewer, yEndWorld, 'Y', Color.GREEN);
    addAxisToViewer(viewer, zEndWorld, 'Z', Color.BLUE);
  }

  const addAxisToViewer = (viewer: any, position: any, text: string, color: any) => {
    viewer.entities.add({
      position : position,
      label : {
        text : text,
        font : '12pt monospace',
        style: LabelStyle.FILL,
        fillColor : color,
        outlineWidth : 10,
        verticalOrigin : VerticalOrigin.BOTTOM,
        pixelOffset : new Cartesian2(0, -9)
      }
    });
  }

  useEffect(() => {
    checkViewerAvailable();

    const handleListenerAction = () => {
      let currentAction = JSON.parse(localStorage.getItem('currentAction') || '{}');
      handleSyncAction(currentAction);
    };

    const params = new URLSearchParams(window.location.search);
    const editPanel = params.get('editPanel');
    if (editPanel === '1') {
      setIsEditMode(true);
    }

    document.addEventListener('dispatchAction', handleListenerAction);
    return () => {
      document.removeEventListener('dispatchAction', handleListenerAction);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Ion.defaultAccessToken = options.accessToken;
  }, [options.accessToken]);
  
  useEffect(() => {
    for (const [index, value] of data.series.entries()) {
      setResources(value, index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, options.accessToken, options.modelAssetId, options.modelAssetUri]);

  useEffect(() => {
    if (!options.subscribeToDataHoverEvent) {
      return;
    }

    const dataHoverSubscriber = eventBus.getStream(DataHoverEvent).subscribe((event) => {
      if (event?.payload?.point?.time) {
        setTimestamp(JulianDate.fromDate(new Date(event.payload.point.time)));
      }
    });

    const graphHoverSubscriber = eventBus.getStream(LegacyGraphHoverEvent).subscribe((event) => {
      if (event?.payload?.point?.time) {
        setTimestamp(JulianDate.fromDate(new Date(event.payload.point.time)));
      }
    });

    return () => {
      dataHoverSubscriber.unsubscribe();
      graphHoverSubscriber.unsubscribe();
    };
  }, [eventBus, options.subscribeToDataHoverEvent]);

  useEffect(() => {
    if (viewerRef?.current?.cesiumElement) {
      const viewer = viewerRef.current.cesiumElement;
      viewer.clock.multiplier = isBack ? clockMultiplier * -1 : clockMultiplier;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clockMultiplier]);

  const series = data.series.map((item, index) => {
    const timeVals = getItemByKey('name', 'time', item.fields)?.values || [];
    const longitudeVals = getItemByKey('name', 'longitude', item.fields)?.values || [];
    const latitudeVals = getItemByKey('name', 'latitude', item.fields)?.values || [];
    const altitudeVals = getItemByKey('name', 'altitude', item.fields)?.values || [];
    const eventVals = getItemByKey('name', 'event', item.fields)?.values || [];
    const color = getColor(index);
    const results = [];

    for (let i = 0; i < timeVals.length; i++) {
      let event = '';
      let description: any;
      let pixelSize = options.trajectoryPositionSize;

      const position = Cartesian3.fromDegrees(
        Number(longitudeVals[i]),
        Number(latitudeVals[i]),
        Number(altitudeVals[i])
      );
      const time = timeVals[i];

      if (!options.simulationType || options.simulationType?.toLowerCase() === SimulationType.ThreeDoF.toLowerCase()) {
        if (eventVals[i]) {
          pixelSize = options.trajectoryPositionSize * 2;
        }
        description = generateDescription(
          toHourString(
            JulianDate.secondsDifference(
              JulianDate.fromDate(new Date(timeVals[i])),
              JulianDate.fromDate(new Date(timeVals[0]))
            )
          ),
          longitudeVals[i],
          latitudeVals[i],
          altitudeVals[i],
          eventVals[i]
        );
      }

      results.push({ time, position, color, pixelSize, event, description });
    }
    return results;
  });

  const handleSyncAction = (currentAction: any) => {
    const viewer = viewerRef.current?.cesiumElement;
    if (currentAction.playForward) {
      const { speed, back, play } = currentAction.playForward;
      viewer.clockViewModel.multiplier = speed;
      setClockMultiplier(speed);
      setIsBack(back);
      viewer.clockViewModel.shouldAnimate = play;
      setShouldAnimate(play);
    }
    if (currentAction.pause) {
      viewer.clockViewModel.shouldAnimate = !viewer.clockViewModel.shouldAnimate;
      setShouldAnimate(viewer.clockViewModel.shouldAnimate);
    }
    if (currentAction.playReverse) {
      const { speed, back, play } = currentAction.playReverse;
      viewer.clockViewModel.multiplier = speed;
      setIsBack(back);
      viewer.clockViewModel.shouldAnimate = play;
      setShouldAnimate(play);
    }
    if (currentAction.speedChange) {
      const { speed } = currentAction.speedChange
      setClockMultiplier(speed);
    }
    if (currentAction.timelineChange) {
      const { currentTime } = currentAction.timelineChange;
      setTimestamp(currentTime);
    }
    if (currentAction.legendChange) {
      const { legends } = currentAction.legendChange;
      setLegends(legends);
    }
  }

  const dispatchAction = (name: string, value: any) => {
    const currentAction = {[name]: value};
    if (isEditMode) {
      handleSyncAction(currentAction);
      return;
    }
    localStorage.setItem('currentAction', JSON.stringify(currentAction));
    const myEvent = new Event('dispatchAction');
    document.dispatchEvent(myEvent);
  };

  return (
    <div className="relative">
      <div
        className="relative font-sans"
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
        ref={wrapRef}
      >
        <Viewer
          full
          animation={false}
          timeline={true}
          infoBox={options.showInfoBox}
          baseLayerPicker={options.showBaseLayerPicker}
          sceneModePicker={options.showSceneModePicker}
          projectionPicker={options.showProjectionPicker}
          navigationHelpButton={false}
          fullscreenButton={false}
          geocoder={false}
          homeButton={false}
          creditContainer="cesium-credits"
          ref={viewerRef}
          className={isStaticPanel() ? 'panel-sm' : ''}
        >
          {timestamp && <Clock currentTime={timestamp} />}
          {satelliteAvailability &&
            satellitePositions &&
            satelliteOrientations &&
            satellitePositions.map(
              (satellitePosition, index) =>
                legends.length > 0 &&
                legends[index]?.is_selected && (
                  <Entity
                    availability={satelliteAvailability}
                    position={satellitePosition}
                    orientation={
                      options.autoComputeOrientation
                        ? new VelocityOrientationProperty(satellitePosition)
                        : satelliteOrientations[index]
                    }
                    tracked={false}
                    ref={modelRef}
                    key={index}
                    name={data.series[index]?.refId}
                  >
                    {options.assetMode === AssetMode.model && satelliteResources[index] && (
                      <ModelGraphics
                        uri={satelliteResources[index]}
                        scale={options.modelScale}
                        minimumPixelSize={options.modelMinimumPixelSize}
                        maximumScale={options.modelMaximumScale}
                        key={index}
                      />
                    )}

                    {options.trajectoryShow && (
                      <PathGraphics
                        width={options.trajectoryWidth}
                        material={
                          new PolylineDashMaterialProperty({
                            color: Color.fromCssColorString(options.trajectoryColor),
                            dashLength: options.trajectoryDashLength,
                          })
                        }
                      />
                    )}
                  </Entity>
                )
            )}

          {(options.trajectoryPositionShow && !isStaticPanel()) &&
            series.map(
              (serie_item, index) =>
                legends.length > 0 &&
                legends[index]?.is_selected &&
                serie_item.map((item, itemIndex) => (
                  <Entity
                    position={item.position}
                    point={{
                      pixelSize: 0 || options.trajectoryPositionSize,
                      color: Color.fromCssColorString(item.color),
                    }}
                    key={index}
                    label={
                      item.event && hoveredIndex?.serie === index && hoveredIndex?.position === itemIndex
                        ? {
                            text: item.event,
                            font: '12px sans-serif',
                            fillColor: Color.WHITE,
                            outlineWidth: 1,
                            outlineColor: Color.BLACK,
                            pixelOffset: new Cartesian2(0, -20),
                          }
                        : undefined
                    }
                    name={data.series[index]?.refId}
                    description={item.description}
                    onMouseMove={() => handleMouseMove(index, itemIndex)}
                    onMouseLeave={handleMouseLeave}
                  ></Entity>
                ))
            )}
        </Viewer>
        <div id="cesium-credits" className={options.showCredits ? 'block' : 'hidden'}></div>
      </div>
      {
        !isStaticPanel() && (
          <>
            <SeriesLegend
              legends={legends}
              onLegendsChange={(newLegends: Legend[]) => dispatchAction('legendChange', {legends: newLegends})}
              portalContainer={wrapRef.current}
            />
            <TopControl
              viewerRef={viewerRef}
              data={data}
              options={options}
              isPlaying={shouldAnimate}
              isBack={isBack}
              setTimestamp={(currentTime: JulianDate) => setTimestamp(currentTime)}
              playReverse={playReverse}
              pause={pause}
              playForward={playForward}
              handleSpeedChange={handleSpeedChange}
              currentSpeed={clockMultiplier}
              legends={legends}
              isStaticPanel={isStaticPanel()}
              dispatchAction={dispatchAction}
              isFinishLoad={isFinishLoad}
            />
          </>
        )
      }
    </div>
  );
};
