import React, { useEffect, useState } from 'react';
import {
  JulianDate,
} from 'cesium';
import { 
  toHourString, 
  getItemByKey
} from 'utilities';

export const ClockControl: React.FC<any> = ({ viewerRef, data, options, pause, isStaticPanel, isFinishLoad }) => {
  const [currentTime, setCurrentTime] = useState<string>(toHourString(0));

  
  useEffect(() => {
    const viewer = viewerRef.current.cesiumElement;
    if (viewer && data.series.length >= 1) {
      const allTimes = data.series
        .flatMap((item: any) => {
          const timeData = getItemByKey('name', 'time', item.fields)?.values || [];
          return [timeData[0], timeData.at(-1)];
        })
        .map((time: string) => new Date(time))
        .sort((a: any, b: any) => a - b);
      const startTimestamp: number | string = allTimes[0] ?? '';
      const startTime = JulianDate.fromDate(new Date(startTimestamp));

      const updateTime = (clock: any) => {
        if (!isFinishLoad) {
          return;
        }
        const { clockViewModel } = viewerRef?.current?.cesiumElement;
        const timeBySecond = JulianDate.secondsDifference(clock.currentTime, startTime);
        setCurrentTime(toHourString(timeBySecond));
        if (clockViewModel.shouldAnimate && timeBySecond === 0) {
          pause();
        }
      }
      viewer.clock.onTick.addEventListener(updateTime);
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [viewerRef.current?.cesiumElement, data, options, isFinishLoad]);

  return (
    <div className={`flex items-center justify-center text-white py-[10px] font-sans mx-[20px] grow`}>
      {currentTime !== '' && <div className={`${isStaticPanel ? 'text-xl' : 'text-5xl'}`}>{currentTime}</div>}
    </div>
  );
}
