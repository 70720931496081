import React, { useState } from 'react';
import {Popover, PopoverTrigger, PopoverContent} from "@nextui-org/react";

export interface Legend {
  id: number;
  name: string;
  color: string;
  is_selected: boolean;
}

export interface LegendProps {
  legends: Legend[];
  onLegendsChange: (legends: Legend[]) => void;
  portalContainer: any;
}

export const SeriesLegend: React.FC<LegendProps> = ({legends, onLegendsChange, portalContainer}) => {
  const [loading, setLoading] = useState<any>(false);

  const showLegendList = (isTruncate = false) => {
    return legends.map((legend) => (
      <div
        key={legend.id}
        onClick={() => handleLegendClick(legend.id)}
        className={`cursor-pointer p-1 m-1 flex items-center ${isTruncate ? 'h-4' : ''}`}
        style={{
          opacity: legend.is_selected ? 1 : 0.5,
        }}
      >
        <span className='bg-[#ccc] w-1 h-[1px] m-[2px]'></span>
        <span
          className='rounded-full w-1 h-1'
          style={{
            backgroundColor: legend.color,
          }}></span>
        <span className='bg-[#ccc] w-1 h-[1px] m-[2px]'></span>
        <div className={`text-white ${isTruncate ? 'truncate ...' : ''}`}>{legend.name}</div>
      </div>
    ));
  }

  const handleLegendClick = async (clickedLegendId: number) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const newLegends = legends.map((legend) => {
      if (legend.id === clickedLegendId) {
        return { ...legend, is_selected: !legend.is_selected };
      }
      return legend;
    });
    onLegendsChange(newLegends);
    // await new Promise(resolve => setTimeout(resolve, 500));
    setLoading(false);
  };

  return (
    <div className='absolute flex items-start bottom-0 p-[5px] left-0 bg-[#00000030] max-w-full'>
      <div className="flex flex-col max-h-[65px] overflow-hidden ">
        {showLegendList(true)}
      </div>
      {
        legends.length > 3 && (
          <Popover size="lg" placement="top" offset={20} showArrow={true} className="before:bg-[#00000050]" portalContainer={portalContainer}>
            <PopoverTrigger>
              <i className="cursor-pointer bi bi-chevron-double-up text-[16px] text-white"></i>
            </PopoverTrigger>
            <PopoverContent className="bg-[#00000050]">
              <div className="px-1 py-2 text-[16px] text-white max-h-80 overflow-y-auto">
                {showLegendList()}
              </div>
            </PopoverContent>
          </Popover>
        )
      }
    </div>
  );
}
