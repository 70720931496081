import React, { useEffect, useState, useRef } from 'react';
import { CustomAnimationControls } from './CustomAnimationControls';
import { ClockControl } from './ClockControl';
import { SpeedControl } from './SpeedControl';
import { AltitudesControl } from './AltitudesControl';
import {Tooltip} from "@nextui-org/react";
import {
  JulianDate,
} from 'cesium';
import { getItemByKey } from 'utilities';


export const TopControl: React.FC<any> = ({ 
  viewerRef,
  data,
  options,
  isPlaying,
  isBack,
  playReverse,
  pause,
  playForward,
  handleSpeedChange,
  currentSpeed,
  legends,
  setTimestamp,
  isStaticPanel
}) => {
  const [progress, setProgress] = useState<number>(0);
  const [speed, setSpeed] = useState<number>(1);
  const [events, setEvents] = useState<any>([]);
  const styles = getStyles(progress, speed);
  const controlWrap = useRef<any>();
  const dragContainer = useRef<any>();

  const onMouseDown = (e: any) => {
    e.preventDefault();
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const onMouseMove = (e: any) => {
    e.preventDefault();
    calculateCurrentTime(e.clientX);
  };

  const onMouseUp = (e: any) => {
    e.preventDefault();
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  const handleHoverPoint = (isHover: boolean ,item: any) => {
    item.isHover = isHover;
    setEvents([...events]);
  };

  const calculateCurrentTime = (currentPosition: number) => {
    const viewer = viewerRef.current.cesiumElement;
    const { clock } = viewer;
    const total = dragContainer.current.offsetWidth;
    const newProgress = (currentPosition / total) * 100;
    const totalTime = JulianDate.secondsDifference(clock.stopTime, clock.startTime);
    const currentSecond = (newProgress * totalTime)/100;
    const currentTime = JulianDate.addSeconds(clock.startTime, currentSecond, new JulianDate());
    setTimestamp(currentTime);
  };

  const handleClickOnLine = (e: any) => {
    if (!e.target.classList.contains('timeline')) {
      return;
    }
    const currentPosition = e.nativeEvent.offsetX;
    calculateCurrentTime(currentPosition);
  };

  const checkViewerAvailable: any = async () => {
    const viewer = viewerRef?.current?.cesiumElement;
    if (viewer && data?.series?.length) {
      const allTimes = data.series
        .flatMap((item: any) => {
          const timeData = getItemByKey('name', 'time', item.fields)?.values || [];
          return [timeData[0], timeData.at(-1)];
        })
        .map((time: string) => new Date(time))
        .sort((a: any, b: any) => a - b);
      const startTimestamp: number | string = allTimes[0] ?? '';
      const endTimestamp: number | string = allTimes.at(-1) ?? '';
      const startTime = JulianDate.fromDate(new Date(startTimestamp));
      const endTime = JulianDate.fromDate(new Date(endTimestamp));
      const totalTime = JulianDate.secondsDifference(endTime, startTime);

      const updateProgress = (clock: any) => { 
        const timeBySecond = JulianDate.secondsDifference(clock.currentTime, startTime);
        const currentProgress = parseFloat(((timeBySecond / totalTime) * 100).toFixed(1));
        setProgress(currentProgress);
      }
      const { clock } = viewer;
      clock.onTick.removeEventListener(updateProgress);
      clock.onTick.addEventListener(updateProgress);
      let eventList: any = [];

      for (const serie of data.series) {
        const legendOfEvent = legends.find((item: any) => item.name === serie.refId);
        const eventVals = getItemByKey('name', 'event', serie.fields)?.values || [];
        const timeVals = getItemByKey('name', 'time', serie.fields)?.values || [];

        for (const [index, value] of eventVals.entries()) {
          if (value && legendOfEvent?.is_selected) {
            const itemTime = JulianDate.fromDate(new Date(timeVals[index]));
            const timeBySecond = JulianDate.secondsDifference(itemTime, startTime);
            const itemProgress = parseFloat(((timeBySecond / totalTime) * 100).toFixed(1));
            const newEventItem = {legend: legendOfEvent, eventName: value};
            
            eventList = eventList.some((item: any) => item.value === itemProgress) ? 
              eventList.map((item: any) => {
                if (item.value === itemProgress) {
                  item.events = [...item.events, newEventItem];
                }
                return item;
              }) : [...eventList, {value: itemProgress, isOpen: false, isHover: false, events: [newEventItem]}];
          }
        }
      }
      setEvents(eventList);
    } else {
      await new Promise(resolve => setTimeout(resolve, 500));
      await checkViewerAvailable();
    }
  };

  useEffect(() => {
      checkViewerAvailable();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legends, options]);

  useEffect(() => {
    setSpeed(1/currentSpeed);
  }, [currentSpeed]);

  return (
    <div className='w-full absolute top-0 left-0 bg-gradient-to-b from-[#00000080] to-[#00000020] p-2' ref={controlWrap}>
      <div className={`flex justify-between flex-wrap`}>
        <div className={`flex gap-x-[20px] justify-start items-center grow`}>
          <CustomAnimationControls 
            isPlaying={isPlaying}
            isBack={isBack}
            playReverse={playReverse}
            pause={pause}
            playForward={playForward}
            isStaticPanel={isStaticPanel}
          />
          <SpeedControl
            handleSpeedChange={handleSpeedChange} 
            currentSpeed={currentSpeed}
            isStaticPanel={isStaticPanel}
          />
          
        </div>
        <ClockControl
          viewerRef={viewerRef}
          data={data}
          options={options}
          pause={pause}
          isStaticPanel={isStaticPanel}
        />
        <div className={`flex items-center justify-end grow`}>
          <AltitudesControl
            viewerRef={viewerRef}
            data={data}
            options={options}
            legends={legends}
            portalContainer={controlWrap.current}
            isStaticPanel={isStaticPanel}
          />
        </div>
      </div>
      
      <div className='py-[10px] cursor-pointer timeline' onClick={handleClickOnLine}>
        <div className="flex h-[2px] w-100 bg-[#52525273] rounded-xl relative timeline" ref={dragContainer}>
          <div className="h-100 bg-white rounded-xl" style={styles.fillLine}>
          </div>
          {
            events.map((item: any, index: number) => {
              const checkPoint = progress - item.value;
              const pointStyle = getStyles(progress, speed, item.value);
              item.isOpen = (checkPoint > 0) && (checkPoint < 0.5);
              
              return (
                <Tooltip
                  className="bg-[#00000050]"
                  portalContainer={controlWrap.current}
                  key={index}
                  size="lg"
                  placement="bottom"
                  isOpen={item.isOpen || item.isHover}
                  content={
                    <div className="px-1 py-2 text-[16px] text-white">
                      {item.events.map((eventDetail: any, eventIndex: number) => (
                        <div className="flex items-center" key={eventIndex}>
                          {
                            legends.length > 1 && (
                              <>
                                <span className='bg-[#ccc] w-1 h-[1px] m-[2px]'></span>
                                <span
                                  className='rounded-full w-1 h-1'
                                  style={{
                                    backgroundColor: eventDetail.legend.color,
                                  }}></span>
                                <span className='bg-[#ccc] w-1 h-[1px] m-[2px]'></span>
                              </>
                            )
                          }
                          {eventDetail.eventName}
                        </div>
                      ))}
                    </div>
                  }
                >
                  <div
                    className="cursor-pointer rounded-full absolute top-[50%] -translate-x-1/2 -translate-y-1/2 hover:p-2"
                    style={pointStyle.eventPoint}
                    onMouseEnter={() => handleHoverPoint(true, item)}
                    onMouseLeave={() => handleHoverPoint(false, item)}
                  />
                </Tooltip>              
              )
            })
          }
          <div
            onMouseDown={onMouseDown}
            className='cursor-pointer flex items-center justify-center h-[10px] w-[10px] border border-white rounded-full absolute top-[50%] -translate-x-1/2 -translate-y-1/2'
            style={styles.outerPoint}
            // style={{ transform: `translateX(${position.x}px)` }}
          >
            <div className="w-1 h-1 bg-white rounded-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

const getStyles: any = (progress: number, speed: number, point: number) => {
  return {
    fillLine: {
      width: `${progress}%`,
      // transition: `width ${speed}s ease-in-out`,
    },
    outerPoint: {
      left: `${progress}%`,
      // transition: `all ${speed}s ease-in-out`,
    },
    eventPoint: {
      height: (progress >= point) ? '7px' : '5px',
      width: (progress >= point) ? '7px' : '5px',
      backgroundColor: (progress >= point) ? '#FFFFFF' : '#ffffff99',
      left: `${point}%`,
    }
  };
};
